import React                                        from 'react';
import { graphql, Link }                            from 'gatsby';
import Img                                          from 'gatsby-image';
import styled                                       from '@emotion/styled';
import { Flex, Box, useMediaQuery, Button, Center } from '@chakra-ui/react'
import { GiDiamondRing, GiWatch, GiShop }           from 'react-icons/gi';
import { BsTools }                                  from 'react-icons/bs';


import Heading           from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer            from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper           from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Carousel          from '@interness/web-core/src/components/media/Carousel/Carousel';
import SEO               from '@interness/web-core/src/components/modules/SEO/SEO';
import BrandsDisplay     from '@interness/theme-sonora/src/components/Display/BrandsDisplay';
import LiveAnnouncements from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';
import MoreExpander      from '@interness/web-core/src/components/structure/MoreExpander/MoreExpander';
import RandomShopItems   from '@interness/ecommerce-addon/src/components/RandomShopItems/RandomShopItems';
import CallToAction      from '@interness/theme-sonora/src/components/CallToAction/CallToAction';

const SDisplay = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const SDisplayItem = styled.div`
    width: ${props => props.width};
    margin-bottom: 50px;
    position: relative;
    @media (max-width: 640px) {
        width: 100%;
    }

    > div {
        margin-bottom: 20px;
        position: relative;
        border: 5px solid rgba(255, 255, 255, 0.8);
    }

    h4 {
        background-color: rgba(255, 255, 255, 0.8);
        color: #333;
        text-transform: uppercase;
        padding: 10px 0;
        margin: auto;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
    }

    h5 {
        font-size: 1.2rem;
        font-weight: 300;
        text-align: left;
        margin-top: 10px;
    }

    p {
        text-align: justify;
        font-size: 0.9rem;
    }

    a {
        position: absolute;
        bottom: -42px;
    }
`;

function IndexPage({ data }) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const serviceItems = [
    {
      title: 'Goldschmiedeatelier',
      descr: 'Gerne bieten wir Ihnen eine individuelle und professionelle Beratung. Wir verwirklichen Ihre speziellen und eigenen Wünsche rund um das Thema Schmuck und realisieren diese mit meisterlichem Können.',
      image: data.services.images[1].file.localFile.childImageSharp.fluid,
    },
    {
      title: 'Uhrmacherwerkstatt',
      descr: 'Ein erstklassiger und schnellstmöglicher Service für Ihre Zeitmesser in unser Uhrmacherwerkstatt ist für uns selbstverständlich. Für Revisionen Ihrer Armbanduhr arbeiten wir mit allen führenden Herstellern zusammen. So ist gewährleistet, dass Ihre Uhr den bestmöglichen Service und nur original Ersatzteile erhält.',
      image: data.services.images[0].file.localFile.childImageSharp.fluid,
    }
  ];
  return (
    <>
      <SEO title={'Startseite'}/>
      <Carousel>
        {data[isMobile ? 'headerImagesMobile' : 'headerImages'].images.map((image) => (
          <Img key={image.file.localFile.name} fluid={image.file.localFile.childImageSharp.fluid}
               alt={image.file.localFile.name}/>
        ))}
      </Carousel>
      <Wrapper>
        <Spacer/>
        <LiveAnnouncements/>
        <section>
          <Heading subtitle={'Juweliertradition seit 1860'} icon={<GiDiamondRing/>}>Juwelier Meckenstock</Heading>
          <Flex flexWrap="wrap">
            <Box w={['100%', '100%', '50%', '50%']} p={[4, 4, 4, 16]}>
              <Box border="5px solid rgba(255,255,255,0.8)">
                <Img fluid={data.laden.images[0].file.localFile.childImageSharp.fluid}/>
              </Box>
            </Box>
            <Box w={['100%', '100%', '50%', '50%']} p={[4, 4, 4, 16]}>
              <p>Herzlich willkommen bei Juwelier Meckenstock in Wuppertal Barmen – Ihrem exklusiven Ansprechpartner für
                außergewöhnlichen Schmuck, hochwertige Uhren und erstklassigen Service.</p>

              <p>Seit mehr als 160 Jahren sind wir stolz darauf, Ihnen als renommierter und traditioneller Juwelier in
                Wuppertal Barmen ein feines Sortiment an erlesenem Schmuck und hochwertigen Uhren präsentieren zu
                können.</p>

              <p>Unsere Partnerschaft mit renommierten Marken wie Tudor, Nomos Glashütte und Mühle Glashütte ermöglicht
                es uns, Ihnen Uhren von höchster Präzision und Qualität anzubieten. Ebenso führen wir weitere namhafte
                Uhrenmarken, die für ihre herausragende Handwerkskunst und zeitloses Design bekannt sind.</p>
              <MoreExpander>
                <p>Entdecken Sie die Vielfalt an eleganten, sportlichen und modischen Armbanduhren, die Ihren
                  persönlichen
                  Stil unterstreichen und Sie zuverlässig durch den Alltag begleiten.
                </p>
                <p>In unserer hauseigenen Goldschmiede und Meisterwerkstatt entstehen einzigartige Schmuckstücke und
                  individuelle Anfertigungen, die Ihre Persönlichkeit perfekt widerspiegeln. Atemberaubende
                  Brillantringe, elegante Colliers und traumhafter Ohrschmuck – wir setzen Ihre Träume in kostbare
                  Realität um.
                  Natürlich folgen wir auch den aktuellen Schmuck-Trends, um sicherzustellen, dass Sie bei Juwelier
                  Meckenstock stets die neuesten und angesagtesten Schmuckstücke entdecken können. Unser Team ist stets
                  auf dem Laufenden über die aktuellen Entwicklungen und Innovationen in der Welt des Schmucks, um Ihnen
                  eine vielfältige Auswahl an trendigen und zeitgemäßen Designs bieten zu können.</p>

                <p>Wir legen großen Wert auf individuelle und persönliche Beratung und ausgezeichneten Service durch
                  unser fachkundiges Team.</p>

                <p>Erleben Sie die zeitlose Eleganz und lassen Sie sich von unserer Leidenschaft für Juwelen und feinste
                  Uhrmacherkunst inspirieren.
                  Wir freuen uns darauf, Sie persönlich bei Juwelier Meckenstock in Wuppertal begrüßen zu können.</p>
                <p>IHRE FAMILIE WALLMICHRATH</p>
              </MoreExpander>
            </Box>
          </Flex>
        </section>
        <Spacer/>
        <section>
          <Heading tag={'h2'} icon={<GiWatch/>}>Aktuelle Highlights</Heading>
          <RandomShopItems/>
          <Spacer/>
          <Center>
            <Button sx={{ textDecoration: 'none' }} colorScheme="brand" as={Link} to="/schaufenster">Mehr Produkte
              entdecken</Button>
          </Center>
        </section>
        <Spacer/>
        <section>
          <Heading tag={'h2'} icon={<BsTools/>}>Individueller Service</Heading>
          <SDisplay>
            {serviceItems.map(item => (
              <SDisplayItem width={'45%'} key={item.title}>
                <div>
                  <Img fluid={item.image} alt={item.title}/>
                  <h4>{item.title}</h4>
                </div>
                <p dangerouslySetInnerHTML={{ __html: item.descr }}/>
              </SDisplayItem>
            ))}
          </SDisplay>
        </section>
        <Spacer/>
        <section>
          <Heading tag={'h2'} icon={<GiShop/>}>Neue Kollektionen entdecken</Heading>
          <BrandsDisplay exclude={['accessories']}/>
        </section>
        <Spacer/>
        <CallToAction/>
      </Wrapper>
    </>
  )
}

export default IndexPage;

export const query = graphql`
    query {
        headerImages: directusMediaCollection(name: {eq: "home"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 1980, maxHeight: 700, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        headerImagesMobile: directusMediaCollection(name: {eq: "home-mobile"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 700, maxHeight: 700, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        laden: directusMediaCollection(name: {eq: "laden"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 800, maxHeight: 800, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        services: directusMediaCollection(name: {eq: "services"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 800, maxHeight: 500, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;